import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, FormControl, Typography } from '@mui/material'
import { t } from 'i18next'
import { useFormContext } from 'react-hook-form'
import IcWarning from '../../../icons/AlertIcon/IcWarning'
import { Styles } from '../../../../types'
import {
  getSelectedPaymentMethod,
  getpayeraccounts,
  gethandleClicked,
  sethandleClicked,
} from '../../../../store/invoices/paymentInfoSlice'
import { getImplementAutoPayLimit } from '../../../../store/invoices/invoiceSelectedInvoicesSlice'

const styles: Styles = {
  errorContainer: (theme) => ({
    marginTop: '0.5rem',
    background: 'rgba(228, 0, 70, 0.14)',

    fontFamily: 'prelo',
    fontWeight: 700,
    verticalAlign: 'bottom',
    alignItems: 'flex-end',

    focusedTab: {
      outline: '2px solid blue', // You can customize this outline style
    },
    [theme.breakpoints.down('desktop')]: {
      flexDirection: 'row',
      padding: '1rem',
      width: '100%',
      marginTop: '0.5rem',
    },
    [theme.breakpoints.down('tablet')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('mobile')]: {
      flexDirection: 'row',
    },
  }),
  cells: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '0.4rem',
    marginTop: '-0.6rem',
    alignItems: 'top',
    marginBottom: '-0.5rem',
    flex: 'auto',
    [theme.breakpoints.down('desktop')]: {},
    [theme.breakpoints.down('tablet')]: {
      flexDirection: 'row',
      width: 'auto',
    },
    [theme.breakpoints.down('mobile')]: {
      flexDirection: 'row',
      width: 'auto',
    },
  }),
  cell: (theme) => ({
    flex: 'none',
    verticalAlign: 'bottom',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    [theme.breakpoints.down('tablet')]: {
      flex: 'row',
      width: 'auto',
    },
    [theme.breakpoints.down('mobile')]: {
      flexDirection: 'row',
      width: 'auto',
    },
  }),
  ErrormessageContainer: (theme) => ({
    width: '100%',
    marginTop: '0.5rem',
    verticalAlign: 'bottom',
    alignItems: 'flex-end',
    fontStyle: 'italic',
    fontFamily: 'prelo',
    fontWeight: '700',
    fontSize: '16px',
    padding: '0',

    [theme.breakpoints.only('mobile')]: {
      width: '12rem',
      paddingLeft: 0,
      textAlign: 'left',
      paddingBottom: '0.5rem',
      fontWeight: '500',
      fontSize: '13px',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: 0,
      width: 'auto',
    },
  }),
  cellTitle: {
    fontWeight: 800,
    marginBottom: '0.5rem',
  },
  linebox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'top',
    gap: '0.5rem',
    marginTop: '0.3rem',
    marginBottom: '-0.5rem',
    height: 'auto',
  },
  description: {
    verticalAlign: 'bottom',
    alignItems: 'flex-end',
    marginTop: '0.5rem',
    fontStyle: 'italic',
    fontFamily: 'prelo',
    fontWeight: '500',
  },
  listitemstyle: {
    display: 'flex',
    alignItems: 'center',
    listStyleType: 'circle',
  },
  link: {
    display: 'block',
    textDecoration: 'underline',
    padding: '0px',
    minWidth: '10px',
    fontSize: '16px',
    fontFamily: 'prelo',
    fontWeight: 'bold',
  },
  buttontext: {},
}
interface PaymentCategoriesErrosDialogProps {
  selectedPaymentCategory: string
}
const PaymentCategoriesErrosDialog = ({
  selectedPaymentCategory,
}: PaymentCategoriesErrosDialogProps) => {
  const {
    formState: { errors, isSubmitted },
  } = useFormContext()
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
  const payerPaymentAccounts = useSelector(getpayeraccounts)
  const implementAutoPayLimit = useSelector(getImplementAutoPayLimit)
  const boxRef = useRef<HTMLElement>(null)
  const isHandleClicked = useSelector(gethandleClicked)
  const dispatch = useDispatch()
  // Determine if there are errors
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [hasPayerError, setHasPayerError] = useState<boolean>(false)
  const [hasPaymentMethodError, setHasPaymentMethodError] =
    useState<boolean>(false)
  const [hasPaymentDateError, setHasPaymentDateError] = useState<boolean>(false)
  const [
    hasNumberOfInstallmentsOtherError,
    setHasNumberOfInstallmentsOtherError,
  ] = useState<boolean>(false)
  const [hasPaymentAmountError, setHasPaymentAmountError] =
    useState<boolean>(false)
  const [hasAutoPayFrequencyError, setHasAutoPayFrequencyError] =
    useState<boolean>(false)
  const [displayErrorBox, setDisplayErrorBox] = useState<boolean>(false)
  useEffect(() => {
    setHasErrors(Object.keys(errors).length > 0)
    if (Object.keys(errors).includes('paymentMethod')) {
      setHasPaymentMethodError(true)
      setDisplayErrorBox(true)
    } else setHasPaymentMethodError(false)

    if (isHandleClicked) {
      setDisplayErrorBox(Object.keys(errors).length > 0)
      setHasNumberOfInstallmentsOtherError(false)
      setHasPaymentAmountError(false)
      if (Object.keys(errors).includes('numberOfInstallmentsOther')) {
        setHasNumberOfInstallmentsOtherError(true)
        setDisplayErrorBox(true)
      } else setHasNumberOfInstallmentsOtherError(false)

      if (Object.keys(errors).includes('paymentAmount')) {
        setHasPaymentAmountError(true)
        setDisplayErrorBox(true)
      } else setHasPaymentAmountError(false)
    }

    if (boxRef.current && isHandleClicked) {
      setHasPayerError(false)
      setHasPaymentDateError(false)
      setDisplayErrorBox(false)
      if (Object.keys(errors).includes('payer')) {
        setHasPayerError(true)
        setDisplayErrorBox(true)
        setHasPaymentMethodError(false)
      }
      if (Object.keys(errors).includes('paymentDate')) {
        setHasPaymentDateError(true)
        setDisplayErrorBox(true)
      }

      if (Object.keys(errors).includes('autoPayFrequency')) {
        setHasAutoPayFrequencyError(true)
        setDisplayErrorBox(true)
      }

      boxRef.current.focus()
      dispatch(sethandleClicked(false))
    }
  })
  const getPaymentMethodError = () => {
    let errorMessage
    if (selectedPaymentMethod === 'BankAccount') {
      errorMessage = payerPaymentAccounts
        ? t('Invoicing.SelectBankAccountError')
        : t('Invoicing.AddNewBankAccount')
    } else {
      errorMessage = payerPaymentAccounts
        ? t('Invoicing.SelectCreditCardError')
        : t('Invoicing.AddNewCreditCard')
    }
    return errorMessage
  }

  const scrollToInput = (id: string) => {
    const inputElement = document.getElementById(id)
    if (inputElement) {
      inputElement.focus()
      const offset = -150 // '-12.5rem'; // Adjust this value according to your header height
      const elementPosition =
        inputElement.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition + offset

      // Delay the scroll action by a small duration
      setTimeout(() => {
        window.scrollTo({
          top: offsetPosition,
          behavior: 'auto', // Use 'auto' instead of 'smooth'
        })
      }, 50) // Adjust this delay duration if needed
    }
  }
  const getCCACHScrollId = () => {
    const getcontrolid = payerPaymentAccounts
      ? t(
          `choose${
            selectedPaymentMethod === 'BankAccount'
              ? 'BankAccount'
              : 'CreditCard'
          }`,
        )
      : `Btn_PaymentMethod_AddNew${selectedPaymentMethod}`
    return getcontrolid
  }
  const getPayerErrorButton = (): JSX.Element => (
    <Button
      onClick={() => {
        scrollToInput('payer')
      }}
      sx={styles.link}
      role="link">
      <Box component="span">
        <strong>{t('Invoicing.Payer')}</strong>
      </Box>
    </Button>
  )
  const getPaymentAmountErrorButton = (): JSX.Element => (
    <Button
      onClick={() => {
        scrollToInput('paymentAmount')
      }}
      sx={styles.link}
      role="link">
      <Box component="span">
        <strong>{t('Invoicing.PayOnAccountPage.PaymentAmount')}</strong>
      </Box>
    </Button>
  )
  const getPaymentLimitErrorButton = (): JSX.Element => (
    <Button
      onClick={() => {
        scrollToInput('paymentAmount')
      }}
      sx={styles.link}
      role="link">
      <Box component="span">
        <strong>{t('Invoicing.AutoPayPage.AutoPayLimitAmount')}</strong>
      </Box>
    </Button>
  )
  const getPayMethodErrorButton = (): JSX.Element => (
    <Button
      onClick={() => {
        scrollToInput(getCCACHScrollId())
      }}
      sx={styles.link}
      role="link">
      <Box component="span">
        <strong> {getPaymentMethodError()}</strong>
      </Box>
    </Button>
  )
  const getPaystartDateButton = (): JSX.Element => (
    <Button
      onClick={() => {
        scrollToInput('PaymentDate')
      }}
      sx={styles.link}
      role="link">
      <Box component="span">
        <strong>{t('Invoicing.PaymentsStartDate')}</strong>
      </Box>
    </Button>
  )
  const getPayDateButton = (): JSX.Element => (
    <Button
      onClick={() => {
        scrollToInput('PaymentDate')
      }}
      sx={styles.link}
      role="link">
      <Box component="span">
        <strong>{t('Invoicing.PaymentDate')}</strong>
      </Box>
    </Button>
  )
  const getNumberOfInstallment = (): JSX.Element => (
    <Button
      onClick={() => {
        scrollToInput('numberOfInstallmentsOther')
      }}
      sx={styles.link}
      role="link">
      <Box component="span">
        <strong>{t('Invoicing.SelectInstallmentPaymentError')}</strong>
      </Box>
    </Button>
  )
  const getAutoPayFrequencyButton = (): JSX.Element => (
    <Button
      onClick={() => {
        scrollToInput('autoPayFrequency')
      }}
      sx={styles.link}
      role="link">
      <Box component="span">
        <strong>{t('Invoicing.AutoPayPage.Frequency')}</strong>
      </Box>
    </Button>
  )

  return (
    <FormControl component="fieldset">
      {isSubmitted && (hasErrors || displayErrorBox) && (
        <Box sx={styles.errorContainer} ref={boxRef} id="cellbox" tabIndex={0}>
          <Box sx={styles.linebox}>
            {/* <LineIcon /> */}
            <Box sx={styles.cells}>
              <Box sx={styles.cell} aria-label="alert">
                <IcWarning />
              </Box>
              <Box sx={styles.cell}>
                <Box
                  sx={styles.ErrormessageContainer}
                  aria-label={t('Alert.ErrorSummaryAlertmessage')}>
                  {t('Alert.ErrorSummaryAlertmessage')}
                </Box>
                <Typography id="errorsum" variant="body1" component="div">
                  {selectedPaymentCategory === t('Invoicing.PayNow') && (
                    <ul
                      style={{
                        listStyleType: 'disc',
                        marginTop: '-1.3rem',
                        padding: '1.0125rem',
                      }}>
                      {(hasPayerError || errors.payer) && (
                        <li>{getPayerErrorButton()}</li>
                      )}
                      {(hasPaymentMethodError || errors.paymentMethod) && (
                        <li>{getPayMethodErrorButton()}</li>
                      )}
                    </ul>
                  )}
                  {selectedPaymentCategory ===
                    t('Invoicing.SchedulePayment') && (
                    <ul
                      style={{
                        listStyleType: 'disc',
                        marginTop: '-1.3rem',
                        padding: '1.0125rem',
                      }}>
                      {(hasPaymentDateError || errors.paymentDate) && (
                        <li>{getPayDateButton()}</li>
                      )}
                      {(hasPayerError || errors.payer) && (
                        <li>{getPayerErrorButton()}</li>
                      )}

                      {(hasPaymentMethodError || errors.paymentMethod) && (
                        <li>{getPayMethodErrorButton()}</li>
                      )}
                    </ul>
                  )}
                  {selectedPaymentCategory ===
                    t('Invoicing.SetupInstallments') && (
                    <ul
                      style={{
                        listStyleType: 'disc',
                        marginTop: '-1.3rem',
                        padding: '1.0125rem',
                      }}>
                      {(hasPaymentDateError || errors.paymentDate) && (
                        <li>{getPaystartDateButton()}</li>
                      )}
                      {(hasNumberOfInstallmentsOtherError ||
                        errors.numberOfInstallmentsOther) && (
                        <li>{getNumberOfInstallment()}</li>
                      )}
                      {(hasPayerError || errors.payer) && (
                        <li>{getPayerErrorButton()}</li>
                      )}
                      {(hasPaymentMethodError || errors.paymentMethod) && (
                        <li>{getPayMethodErrorButton()}</li>
                      )}
                    </ul>
                  )}
                  {selectedPaymentCategory === t('Invoicing.PayOnAccount') && (
                    <ul
                      style={{
                        listStyleType: 'disc',
                        marginTop: '-1.3rem',
                        padding: '1.0125rem',
                      }}>
                      {(hasPayerError || errors.payer) && (
                        <li>{getPayerErrorButton()}</li>
                      )}
                      {hasPaymentAmountError && (
                        <li>{getPaymentAmountErrorButton()}</li>
                      )}
                      {(hasPaymentMethodError || errors.paymentMethod) && (
                        <li>{getPayMethodErrorButton()}</li>
                      )}
                    </ul>
                  )}
                  {selectedPaymentCategory === t('Invoicing.SetupAutoPay') && (
                    <ul
                      style={{
                        listStyleType: 'disc',
                        marginTop: '-1.3rem',
                        padding: '1.0125rem',
                      }}>
                      {(hasAutoPayFrequencyError ||
                        errors.autoPayFrequency) && (
                        <li>{getAutoPayFrequencyButton()}</li>
                      )}
                      {(hasPaymentDateError || errors.paymentDate) && (
                        <li>{getPaystartDateButton()}</li>
                      )}
                      {(hasPaymentAmountError || errors.paymentAmount) &&
                        implementAutoPayLimit === 'Yes' && (
                          <li>{getPaymentLimitErrorButton()}</li>
                        )}
                      {(hasPayerError || errors.payer) && (
                        <li>{getPayerErrorButton()}</li>
                      )}
                      {(hasPaymentMethodError || errors.paymentMethod) && (
                        <li>{getPayMethodErrorButton()}</li>
                      )}
                    </ul>
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </FormControl>
  )
}

export default PaymentCategoriesErrosDialog

import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ApiResponseOfAutopaySetupResponseViewModel,
  AutoPayRecurrenceInterval,
  InvoicePayerViewModel,
} from '@rsmus/ecp-financeservice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useFormContext } from 'react-hook-form'
import { Box, styled } from '@mui/material'
import { TFunction } from 'i18next'
import AutoPayLimit from './PaymentComponents/AutoPayLimit'
import PaymentDate from './PaymentDate'
import Payer from './PaymentComponents/Payer'
import PaymentMethodRadioGroup from './PaymentComponents/PaymentMethodRadioGroup'
import {
  getSelectedBankAccount,
  getSelectedCreditCard,
  getSelectedPayer,
  getSelectedPaymentMethod,
  SelectedAccountTypeState,
  setAccountSelectedType,
  setConfirmPaymentError,
  setDoNotSave,
  setNewPaymentMethod,
  setSelectedBankAccount,
  setSelectedCreditCard,
  setSelectedPayer,
  setSelectedPaymentMethod,
} from '../../../store/invoices/paymentInfoSlice'
import api from '../../../api'
import PaymentMethod from './PaymentMethod'
import {
  AutoPaySetup,
  getImplementAutoPayLimit,
  getIsEditAutoPay,
  getPayOnAccountClient,
  ImplementAutoPayLimitType,
  setAutoPaySetup,
  setImplementAutoPayLimit,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import TermsAndConditionsDialog from './TermsAndConditionsDialog'
import AccountInfoSubText from './PaymentComponents/AccountInfoSubText'
import PaymentAmount from './PaymentComponents/PaymentAmount'
import Spinner from '../../forms/Spinner/Spinner'
import AutoPayFrequency from './PaymentComponents/AutoPayFrequency'
import Heading from '../../typography/Heading/Heading'
import { Styles } from '../../../types'
import {
  REACT_APP_AUTOPAY_MAX_PAYMENT_AMOUNT,
  REACT_APP_AUTOPAY_MIN_PAYMENT_AMOUNT,
} from '../../../envVariables'

interface AutoPayProps {
  formSubmitCount: number
  payerChange: (payer: InvoicePayerViewModel | undefined) => void
  sessionCreating: () => void
  sessionChange: (sessionId: string, isError: boolean) => void
}

const paymentLimitErrorValidationRules = (
  value: string,
  t: TFunction<'translation', undefined>,
) => {
  const isValidInput = /^(0|[1-9]\d*)([.,]\d+)?$/.test(value) // Only positivie, decimal numbers
  const isValidCurrency = /^\d*[.,]?\d{0,2}$/.test(value) // no more than two decimal points
  const isLessThanMinAmount =
    parseFloat(value) < REACT_APP_AUTOPAY_MIN_PAYMENT_AMOUNT // minimum that can be paid for autopay
  const isMoreThanMaxAmount =
    parseFloat(value) > REACT_APP_AUTOPAY_MAX_PAYMENT_AMOUNT // maximum that can be paid for autopay

  if (value.length === 0) {
    return `${t('Invoicing.AutoPayPage.LimitAmountRequired')}`
  }

  if (value.length > 0 && !isValidInput) {
    return `${t('Invoicing.PayOnAccountPage.InvalidCharacters')} ${t(
      'Invoicing.AutoPayPage.ValidAmount',
    )}`
  }

  if (!isValidCurrency) {
    return t('Invoicing.AutoPayPage.ValidAmount').toString()
  }

  if (value.length > 0 && isLessThanMinAmount) {
    return `${t('Invoicing.AutoPayPage.LimitAmountMinimumRequired', {
      minAmount: REACT_APP_AUTOPAY_MIN_PAYMENT_AMOUNT,
    })}`
  }

  if (value.length > 0 && isMoreThanMaxAmount) {
    return `${t('Invoicing.AutoPayPage.LimitAmountMaximumRequired', {
      maxAmount: REACT_APP_AUTOPAY_MAX_PAYMENT_AMOUNT.toString().replace(
        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
        ',',
      ),
    })}`
  }
  return true
}

const RowArea = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexBasis: 'auto',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  [theme.breakpoints.only('mobile')]: {
    flexDirection: 'column',
  },
}))

const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: 'Prelo-Black, sans-serif',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  color: theme.palette.text.primary,
  display: 'block',
}))

const styles: Styles = {
  fieldContainer: (theme) => ({
    width: '50%',
    paddingTop: 0,
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      paddingLeft: 0,
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: 0,
    },
  }),
  CategoryHeader: {
    fontFamily: 'Prelo-Bold, sans-serif',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    paddingBottom: '1.25rem',
  },
}

const AutoPay = ({
  formSubmitCount,
  payerChange,
  sessionCreating,
  sessionChange,
}: AutoPayProps) => {
  const { getValues, reset, clearErrors } = useFormContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
  const payOnAccountClient = useSelector(getPayOnAccountClient)
  const selectedBankAccount = useSelector(getSelectedBankAccount)
  const selectedCreditCard = useSelector(getSelectedCreditCard)
  const implementAutoPayLimit = useSelector(getImplementAutoPayLimit)
  const selectedPayer = useSelector(getSelectedPayer)
  const isEditAutoPay = useSelector(getIsEditAutoPay)

  const [openTermsDialog, setOpenTermsDialog] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [paymentStartDateSubMessage, setPaymentStartDateSubMessage] =
    useState<string>('')
  const [paymentLimit, setPaymentLimit] = useState<number>(0)
  const [selectedFrequency, setSelectedFrequency] =
    useState<AutoPayRecurrenceInterval>()
  const unselectedStatus: SelectedAccountTypeState = 'Unselected'
  const defaultImplementAutoPayLimitType: ImplementAutoPayLimitType = 'No'

  const resetPaymentMethodState = () => {
    dispatch(setSelectedBankAccount(undefined))
    dispatch(setSelectedCreditCard(undefined))
    dispatch(setSelectedPayer(undefined))
    dispatch(setAccountSelectedType(unselectedStatus))
    dispatch(setNewPaymentMethod(false))
    dispatch(setDoNotSave(false))
    dispatch(setImplementAutoPayLimit(defaultImplementAutoPayLimitType))
  }

  const handleSetupAutopay = useCallback(async () => {
    setIsLoading(true)

    try {
      const clientId = Number(payOnAccountClient?.clientId)
      const startDate = new Date(getValues('paymentDate'))

      let response: ApiResponseOfAutopaySetupResponseViewModel
      if (isEditAutoPay) {
        response = await api.finance.payment_UpdateAutoPay({
          clientId,
          clientPaymentMethodId:
            selectedPaymentMethod === 'BankAccount'
              ? selectedBankAccount ?? 0
              : selectedCreditCard ?? 0,
          paymentLimit: paymentLimit === 0 ? undefined : paymentLimit,
          startDate,
          paymentFrequency:
            selectedFrequency ?? AutoPayRecurrenceInterval.Monthly,
        })
      } else {
        response = await api.finance.payment_SetupAutoPay({
          clientId,
          clientPaymentMethodId:
            selectedPaymentMethod === 'BankAccount'
              ? selectedBankAccount ?? 0
              : selectedCreditCard ?? 0,
          paymentLimit: paymentLimit === 0 ? undefined : paymentLimit,
          startDate,
          paymentFrequency:
            selectedFrequency ?? AutoPayRecurrenceInterval.Monthly,
        })
      }

      const autoPaySetup: AutoPaySetup = {
        companyName: (payOnAccountClient?.name as string) ?? '',
        payer: selectedPayer?.name ?? '',
        paymentMethod: selectedPaymentMethod,
        frequency: response.data?.recurrenceInterval
          ? t(`Invoicing.AutoPayPage.${response.data?.recurrenceInterval}`)
          : '',
        startDate: startDate.toString(),
        autoPaylimit: response.data?.paymentLimit,
        accountNumber: response.data?.paymentAccountNumber,
      }

      reset()
      dispatch(setAutoPaySetup(autoPaySetup))
      setIsLoading(false)
      resetPaymentMethodState()
      navigate('/invoicing/invoices/payment-success')
    } catch {
      dispatch(setConfirmPaymentError(true))
      setIsLoading(false)
    }
  }, [
    selectedPaymentMethod,
    selectedBankAccount,
    selectedCreditCard,
    selectedFrequency,
    implementAutoPayLimit,
    selectedPayer,
    payOnAccountClient,
    paymentLimit,
    getValues,
    dispatch,
    navigate,
  ])

  const handleFrequencyChange = (
    frequency: AutoPayRecurrenceInterval | undefined,
  ) => {
    let subMessage = ''

    if (frequency === AutoPayRecurrenceInterval.Daily) {
      subMessage = 'Invoicing.AutoPayPage.PaymentStartDateDailyMessage'
    }

    if (frequency === AutoPayRecurrenceInterval.Weekly) {
      subMessage = 'Invoicing.AutoPayPage.PaymentStartDateWeeklyMessage'
    }

    if (frequency === AutoPayRecurrenceInterval.BiWeekly) {
      subMessage = 'Invoicing.AutoPayPage.PaymentStartDateBiWeeklyMessage'
    }

    if (frequency === AutoPayRecurrenceInterval.Monthly) {
      subMessage = 'Invoicing.AutoPayPage.PaymentStartDateMonthlyMessage'
    }

    setPaymentStartDateSubMessage(subMessage)
    setSelectedFrequency(frequency)
    clearErrors('paymentAmount')
  }

  // When the form is submitted, open the terms and conditions dialog.
  useEffect(() => {
    if (formSubmitCount > 0) {
      setOpenTermsDialog(true)
    }
  }, [formSubmitCount])

  useEffect(() => {
    dispatch(setSelectedPaymentMethod('BankAccount'))
  }, [])

  return (
    <Box style={{ width: '100%' }}>
      <Spinner open={isLoading} />
      <RowArea>
        <Box sx={styles.fieldContainer}>
          <Box component="h2" sx={styles.CategoryHeader}>
            {t('Invoicing.AutoPayPage.PaymentInformationHeader')}
          </Box>
          <StyledLabel sx={{ paddingBottom: 0 }}>
            {t('Invoicing.PayOnAccountPage.Account')}
            <span className="sr-only">{t('srOnlyRequired')}</span>
          </StyledLabel>
          <Heading
            variant="h2"
            data-testid={`Hed_AutoPay_${payOnAccountClient?.name}`}>{`${payOnAccountClient?.name} - ${payOnAccountClient?.clientId}`}</Heading>
        </Box>
      </RowArea>
      <RowArea>
        <AutoPayFrequency frequencyChange={handleFrequencyChange} />
      </RowArea>
      <RowArea>
        <PaymentDate
          labelKey="Invoicing.AutoPayPage.PaymentStartDate"
          subMessage={paymentStartDateSubMessage}
          errorKey="Invoicing.AutoPayPage.PaymentStartDateRequired"
        />
      </RowArea>
      <RowArea>
        <AutoPayLimit />
      </RowArea>
      {implementAutoPayLimit === 'Yes' && (
        <RowArea>
          <PaymentAmount
            label={t('Invoicing.AutoPayPage.AutoPayLimitAmount')}
            errorValidationRules={paymentLimitErrorValidationRules}
            handleChange={(amount: number) => setPaymentLimit(amount)}
          />
        </RowArea>
      )}
      <RowArea>
        <Payer
          payerChange={payerChange}
          errorKey="Invoicing.AutoPayPage.PayerRequired"
        />
      </RowArea>
      {payOnAccountClient?.isCreditCardEnabled && (
        <RowArea>
          <PaymentMethodRadioGroup />
        </RowArea>
      )}
      <AccountInfoSubText />
      <RowArea sx={{ marginTop: '0.5rem' }}>
        <PaymentMethod
          paymentMethodType={selectedPaymentMethod}
          isScheduledPayment
          sessionCreating={sessionCreating}
          sessionChange={sessionChange}
          errorKey={
            selectedPaymentMethod === 'BankAccount'
              ? t('Invoicing.AutoPayPage.BankAccountRequired')
              : t('Invoicing.AutoPayPage.CreditCardRequired')
          }
        />
      </RowArea>
      <TermsAndConditionsDialog
        isAutomaticPayment
        open={openTermsDialog}
        onAccept={() => {
          setOpenTermsDialog(false)
          handleSetupAutopay()
        }}
        onClose={() => setOpenTermsDialog(false)}
        hasUserReadTerms={false}
      />
    </Box>
  )
}

export default AutoPay

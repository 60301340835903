import React from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import {
  AutoPaySetup,
  getIsEditAutoPay,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import InvoicesHeader from '../Invoices/InvoicesHeader'
import { getPaymentMethodDisplay } from './PaymentSuccess.service'
import { formatCurrency } from '../../../rsmCoreComponents/utils/formatters'

interface AutoPaySetupSuccessProps {
  autoPaySetup: AutoPaySetup
}

const styles: Styles = {
  detailContainer: (theme) => ({
    [theme.breakpoints.only('desktop')]: {
      paddingTop: '4rem',
      marginLeft: '6.5rem',
      marginRight: '6.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingTop: '4rem',
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  }),
  detailSection: () => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
  }),
  details: () => ({
    flex: '1 1 auto',
  }),
  detailRow: () => ({
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'start',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
  }),
  detailLabel: (theme) => ({
    fontWeight: 'bold',
    [theme.breakpoints.only('desktop')]: {
      minWidth: '15rem',
    },
    [theme.breakpoints.only('tablet')]: {
      minWidth: '10rem',
      fontSize: '0.875rem',
    },
    [theme.breakpoints.only('mobile')]: {
      minWidth: '9rem',
      fontSize: '0.875rem',
    },
  }),
  detailLabelContent: (theme) => ({
    [theme.breakpoints.only('tablet')]: {
      minWidth: '10rem',
      fontSize: '0.875rem',
    },
    [theme.breakpoints.only('mobile')]: {
      minWidth: '3rem',
      fontSize: '0.875rem',
      paddingLeft: '0.5rem',
    },
  }),
  autopay: () => ({
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20rem',
    height: '10rem',
  }),
}

const AutoPaySetupSuccess = ({ autoPaySetup }: AutoPaySetupSuccessProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isDesktop } = useDeviceType()
  const isEditAutoPay = useSelector(getIsEditAutoPay)

  return (
    <>
      <InvoicesHeader
        primaryHeaderId="AutoPay"
        primaryHeaderText={
          isEditAutoPay
            ? t('Invoicing.AutoPaySetupSuccessPage.AutoPayEdit')
            : t('Invoicing.AutoPaySetupSuccessPage.AutoPaySetup')
        }
        primaryHeaderDescription={
          isEditAutoPay
            ? t('Invoicing.AutoPaySetupSuccessPage.AutoPayEditDescription')
            : t('Invoicing.AutoPaySetupSuccessPage.AutoPaySetupDescription')
        }
        backButtonId="BackToInvoices"
        backButtonText={t('Invoicing.AutoPaySetupSuccessPage.BackToInvoices')}
        handleNavigation={() => navigate(`/invoicing/invoices`)}
      />
      <Box sx={{ backgroundColor: tokens.colors.white }}>
        <Box sx={styles.detailContainer}>
          <Box sx={styles.detailSection}>
            <Box sx={styles.details}>
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.AutoPaySetupSuccessPage.Frequency')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {autoPaySetup?.frequency}
                </Box>
              </Box>
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.AutoPaySetupSuccessPage.AutoPayStartDate')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {t(
                    'Invoicing.AutoPaySetupSuccessPage.AutoPayStartDateFormat',
                    {
                      date: autoPaySetup?.startDate,
                    },
                  )}
                </Box>
              </Box>
              {autoPaySetup?.autoPaylimit && (
                <>
                  <hr aria-hidden="true" />
                  <Box sx={styles.detailRow}>
                    <Box sx={styles.detailLabel}>
                      {t('Invoicing.AutoPaySetupSuccessPage.AutoPayLimit')}
                    </Box>
                    <Box sx={styles.detailLabelContent}>
                      {formatCurrency(autoPaySetup?.autoPaylimit || 0, 'USD')}
                    </Box>
                  </Box>
                </>
              )}
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.AutoPaySetupSuccessPage.PaymentMethod')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {getPaymentMethodDisplay(
                    autoPaySetup?.paymentMethod,
                    autoPaySetup?.accountNumber,
                  )}
                </Box>
              </Box>
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.AutoPaySetupSuccessPage.PayerName')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  <Box>{autoPaySetup?.companyName}</Box>
                  <Box>{autoPaySetup?.payer}</Box>
                </Box>
              </Box>
            </Box>
            {isDesktop && <Box sx={styles.autopay} />}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default AutoPaySetupSuccess
